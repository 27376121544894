<template>
    <div class="max-w-content mx-auto border-black">
        <NTheme>
            <NCollapse
                arrow-placement="right"
                class="akkordeon-collapse"
                :default-expanded-names="
                    CONFIG_OPTIONS.akkordeon.firstOpen ? [0] : ''
                "
                :accordion="CONFIG_OPTIONS.akkordeon.closeAkkordeons"
            >
                <template #arrow>
                    <i
                        :class="[
                            CONFIG_OPTIONS.global.iconStyle,
                            CONFIG_OPTIONS.akkordeon.openingIcon,
                        ]"
                    ></i>
                </template>
                <NCollapseItem
                    class="border-black cursor-pointer px-6"
                    v-for="(element, index) in data"
                    :key="index"
                    :title="element.ueberschrift"
                    :name="index"
                >
                    <div
                        class="ckeditor-content"
                        v-html="element.formatierterText"
                    ></div>
                </NCollapseItem>
            </NCollapse>
        </NTheme>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { Module_AkkordeonEintrag_BlockType } from "@gql-types/types.generated";
import { NCollapse, NCollapseItem } from "naive-ui";
import { CONFIG_OPTIONS } from "@src/globals";
import NTheme from "@src/components/common/NTheme.vue";

const props = defineProps<{
    data: Module_AkkordeonEintrag_BlockType[];
    firstOpen?: boolean;
    closeAkkordeons?: boolean;
}>();

const initialState = ref([]);

props.data.forEach((element, index) => {
    if (props.firstOpen && index == 0) {
        initialState.value.push("open");
    } else {
        initialState.value.push("closed");
    }
});
</script>

<style lang="scss">
.akkordeon-collapse {
    @apply py-5 border-y-2 border-black;
    .n-collapse-item.n-collapse-item--active
        .n-collapse-item__header.n-collapse-item__header--active
        .n-collapse-item-arrow {
        transform: rotate(180deg);
    }

    .n-collapse-item-arrow {
        @apply ml-auto #{!important};
    }

    .n-collapse-item__header-main {
        font-size: var(--text-regular-size);
        font-weight: 700 !important;
        @screen minM {
            font-size: var(--text-medium-size);
        }
    }

    .n-collapse-item:not(:first-child) {
        @apply mt-5 border-t-2 border-black;
        .n-collapse-item__header {
            @apply pt-5 #{!important};
        }
    }
}
</style>
